.dark_mode {
  margin-top: -20px;
  margin-left: 10px;
  z-index: 80;
}

.dark_mode_label {
  width: 65px;
  height: 30px;
  position: relative;
  display: block;
  background: #ebebeb;
  border-radius: 200px;
  box-shadow: inset 0px 5px 10px rgba(107, 107, 107, 0.149),
      inset 0px -5px 15px rgba(107, 107, 107, 0.149);
  cursor: pointer;
  transition: 0.3s;
  background-color: #E7E7E7;
}
.dark_mode_label:after {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  top: 3px;
  left: 3px;
  background: linear-gradient(180deg, #7F5AF0, #7F5AF0);
  border-radius: 180px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.dark_mode_input {
    width: 0;
    height: 0;
    visibility: hidden;
}
.dark_mode_input:checked + .dark_mode_label {
    background: #242424;
}
.dark_mode_input:checked + .dark_mode_label:after {
  left: 62px;
  transform: translateX(-100%);
  background: linear-gradient(180deg, #777, #3a3a3a);
}
.dark_mode_label:active:after {
  width: 30px;
}

.dark_mode_label svg {
  position: absolute;
  width: 20px;
  top: 5px;
  z-index: 100;
}

.dark_mode_label svg.sun {
  left: 5px;
  fill: #fff;
  transition: 0.3s;
}
.dark_mode_label svg.moon {
  left: 40px;
  fill: #171144;
  transition: 0.3s;
}
.dark_mode_input:checked + .dark_mode_label svg.sun {
    fill: #D7CFE3;
}
.dark_mode_input:checked + .dark_mode_label svg.moon {
    fill: #D7CFE3;
}

@media (min-width: 0px) {
  .dark_mode_label {
    width: 56px;
    height: 25px;
  }

  .dark_mode_label:after { 
    width: 20px;
    height: 20px;
  }

  .dark_mode_label svg.moon {
    left: 35px;
  }

  .dark_mode_label svg {
    width: 15px;
  }

  .dark_mode_input:checked+.dark_mode_label:after {
    left: 52px;
  }
}

@media (min-width: 768px) {
  .dark_mode_label {
    width: 65px;
    height: 30px;
  }

  .dark_mode_label:after {
    width: 25px;
    height: 25px;
  }

  .dark_mode_label svg.moon {
    left: 40px;
  }

  .dark_mode_label svg {
    width: 20px;
  }

  .dark_mode_input:checked+.dark_mode_label:after {
    left: 62px;
  }
}