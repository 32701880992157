body {
  background-color: var(--body_background);
  color: var(--body_color);
  position: relative;
}

.primary-btn {
  background-color: var(--primary_color);
}

.card {
  border: 1.5px solid var(--primary_color);
}