@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1 {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
}

.navbar {
  background-color: var(--body_background);
}

:root {
  --body_background: #E7E7E7;
  --primary_color: #7F5AF0;
  --arrow_hover: rgba(75, 75, 75, 0.25);
}

[data-theme="dark"] {
  --body_background: #16161A;
  --body_color: #EBE7F2;
  --primary_color: #7F5AF0;
  --arrow_hover: rgba(75, 75, 75, 0.75);
}

.for_light_theme {
  --primary-color: #7F5AF0;
  --text-color: #171144;
  --background-color: #E7E7E7;
}

.for_dark_theme {
  --primary-color: #7F5AF0;
  --text-color: #EBE7F2;
  --background-color: #16161A;
}

.mobile-menu {
  background-color: var(--body_background);
  color: var(--body_color);
  border-left: 1.5px solid var(--primary-color);
}


.home-icon {
  color: red;
}

/* Navigation items hover effect */
.hover-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #7F5AF0;
  transition: width .3s;
}

.hover-link:hover::after {
  width: 100%;
  transition: width .3s;
}

.arrow-bg {
  width: 80px;
  height: 80px;
  background: var(--arrow_hover);
  opacity: 0.5;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  transform: scale3d(1, 1, 1);
  z-index: 1;
  transition: all 0.5s;
  /* Transition definition with 1 second duration and animate on all properties */
}

.arrow-bg:hover {
  transform: scale3d(0.75, 0.75, 0.75);
  opacity: .4;
}
